import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import styled from 'styled-components'

import { isIE, isChrome } from 'react-device-detect';

const CloseButton = styled.div`
position: absolute;
top: 10px;
right: 12px;
cursor: pointer;
height: 20px;
width: 20px;
`

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    maxWidth: '500px',
    padding: '2rem',
    position: 'relative'
  },
}));

function IsEI() {
  const classes = useStyles();
  const [open, setOpen] = React.useState(true);


  // const handleOpen = () => {
  //   setOpen(true);
  // };

  const handleClose = () => {
    setOpen(false);
  };


  return (
    <div>
      {/* <button type="button" onClick={handleOpen}>
        react-transition-group
      </button> */}
      {isIE? (<><Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <CloseButton onClick={handleClose}><img src="/app-icons/layout-icons/close-round.svg"/></CloseButton>
            <h4 style={{ marginTop: '1rem' }}><strong>Oh no!</strong> It looks like you are using Internet Explorer.</h4>
            <p>Please switch to a modern browser (<a target="_blank" href="https://www.google.com/chrome/">Google Chrome</a>, <a target="_blank" href="http://www.mozilla.org/">Mozilla Firefox</a>, <a target="_blank" href="https://www.microsoft.com/en-us/edge">Microsoft Edge</a>, <a target="_blank" href="http://www.opera.com/">Opera</a> etc.) to continue with the sign-up process.</p>
          </div>
        </Fade>
      </Modal></>) : null}
      
    </div>

  )
}

export default IsEI
